* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

body {
  // padding-bottom: pxToRem(69);
}

h2, h3 {
  @extend .font--ten-mincho;
}

@include mq() {
  #footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}