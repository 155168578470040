#vanilla {
  padding-bottom: pxToRem(32);
  background-color: #e6e2d4;

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .index-section-wrapper {
    background-color: #c7adcd;
  }

  .page-content {
    max-width: 100%;
    margin-right: pxToRem(21);
    margin-left: pxToRem(21);
    padding: 0;
    padding-top: pxToRem(65);
    padding-bottom: pxToRem(85);
    border: pxToRem(2) solid #d3c17a;
    box-shadow: pxToRem(1) pxToRem(1) pxToRem(6) pxToRem(1) rgba(0, 0, 0, 0.46);

    @include mq() {
      margin-right: pxToRem(11);
      margin-left: pxToRem(11);
      padding-top: pxToRem(30);
      padding-bottom: pxToRem(40);
    }

    .content-inner {
      max-width: pxToRem(1020);
      margin: 0 auto;
    }
  }

  .purchase__container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq() {
      flex-direction: column;
    }
  }

  .purchase__pierce,
  .purchase__earrings {
    width: 50%;

    @include mq() {
      width: 100%;
    }
  }

  .purchase__pierce {
    @include mq() {
      margin-bottom: pxToRem(20);
    }
  }

  .item__button {
    padding: 0;
    background: transparent;
    border: none;
  }
}