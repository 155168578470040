#modal {
  .Index-page-content {
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0 !important;
  }
}

#modal {
  line-height: 1;

  .page-content {
    padding: 0;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateY(100vh);
    transition: transform 10ms 300ms;
    z-index: 1000;
    &.open {
      transform: translateY(0);
      transition: transform 10ms;
      .modal__backdrop {
        background-color: rgba(0, 0, 0, 0.53);
        transition: background-color 300ms 10ms;
      }
      .modal__content {
        transform: translateY(0);
        transition: transform 300ms;
      }
    }
  }
}

#modal {
  .modal {
    &__backdrop {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms;
    }
  }
}

#modal {
  .modal {
    &__content {
      position: relative;
      transform: translateY(100vh);
      transition: transform 300ms;
      overflow: visible;
    }
  }
}

#modal {
  .modal {
    &__close {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: pxToRem(5) pxToRem(7);
      @include fontSizePxToRem(19);
      background-color: #e8e5e5;
      border-radius: 50%;
      transform: translate(pxToRem(8), pxToRem(-9));
      cursor: pointer;
    }
  }
}

#modal {
  .content {
      background-color: #e6e2d4;
      border-radius: pxToRem(6);
  }
}

#modal {
  .content {
    &__inner {
      padding: pxToRem(36) pxToRem(35) pxToRem(36) pxToRem(35);

      @include mq(sm) {
        padding: pxToRem(16) pxToRem(15) pxToRem(16) pxToRem(15);
      }
    }

    &__items {
      @include mq() {
        max-height: 55vh;
        overflow-y: scroll;
      }
    }
  }
}

#modal {
  .item {
    padding-top: pxToRem(16);
    padding-bottom: pxToRem(8);
    border-bottom: pxToRem(1) solid #999375;

    &__set {
      padding-top: 0;
    }

    &__info {
      display: flex;
      justify-content: flex-start;
      margin-bottom: pxToRem(16);
    }

    &__text {
      margin-right: pxToRem(10);
    }

    &__title {
      margin: 0;
      margin-bottom: pxToRem(7);
      font-size: pxToRem(14);
      @extend .font--ten-mincho;
    }

    &__price {
      margin: 0;
      font-size: pxToRem(14);
      @extend .font--ten-mincho;
    }

    &__tax {
      margin-left: pxToRem(5);
      font-size: pxToRem(10);
      @extend .font--ten-mincho;
    }

    &__image {
      width: pxToRem(37);
      height: pxToRem(37);
    }

    &__purchase {
      display: flex;
      justify-content: center;
    }

    &__date {
      margin: 0;
      margin-top: pxToRem(10);
      @include fontSizePxToRem(12);
      @extend .font--ten-mincho;
    }
  }
}

#modal {
  .purchase {
    &__button {
      padding: 0;
      background: transparent;
      border: none;
    }

    &__image {
      width: pxToRem(113);
    }
  }
}