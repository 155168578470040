body {
  padding-bottom: pxToRem(55);

  @include mq() {
    padding-bottom: pxToRem(69);
  }
}

#floatingfooter {
  display: flex;
  justify-content: center;
  align-items: center;

  .Index-page-content {
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0 !important;
  }
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  * {
    line-height: 1;
  }

  width: 100vw;
  height: pxToRem(55);
  height: constant(55px + env(safe-area-inset-bottom));
  height: calc(55px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #514362;
  opacity: 0;
  z-index: 999;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  @include mq() {
    height: pxToRem(69);
    height: constant(69px + env(safe-area-inset-bottom));
    height: calc(69px + env(safe-area-inset-bottom));
  }

  .page-content {
    padding: 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(375);
    margin: 0 auto;
    @include mq(sm) {
      max-width: pxToRem(320);
    }
  }
}

#floatingfooter {
  .container {
    .item {
      display: flex;
      flex-direction: column;
      color: #FFFFFF;

      &__amount {
        margin: 0;
        @extend .font--ten-mincho;
        @include fontSizePxToRem(12);

        &--large {
          @include fontSizePxToRem(16);
        }
      }

      &__text {
        margin: 0;
        margin-top: pxToRem(6);
        @extend .font--ten-mincho;
        @include fontSizePxToRem(10);
      }

      &__button {
        width: 34%;
        margin-left: pxToRem(12);
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;

        img {
          transform: translateY(pxToRem(1));
        }
      }
    }
  }
}