#header {
  display: none;
}

body {
  font-family: YakuHanJP, a-otf-gothic-bbb-pr6n, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, a-otf-midashi-go-mb31-pr6n, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms;
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

.font--ten-mincho, h2, h3, #top .top__brand, #floatingfooter .container .item__amount, #floatingfooter .container .item__text, #modal .item__title, #modal .item__price, #modal .item__tax, #modal .item__date {
  font-family: ten-mincho, serif;
  font-style: normal;
  font-weight: 400;
}

.font--eb-garamond, #top .top__producedby {
  font-family: eb-garamond, serif;
  font-style: normal;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

@media screen and (max-width: 735px) {
  #footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

#top .sqs-layout .sqs-row {
  margin: 0 !important;
}

#top .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #top .sqs-block {
    padding: 0 !important;
  }
}

#top .index-section-wrapper {
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0;
  padding: 0;
  padding-bottom: 4rem;
}

@media screen and (max-width: 735px) {
  #top .index-section-wrapper {
    padding-bottom: 2rem;
  }
}

#top .top__header {
  position: relative;
  width: 100vw;
  padding-top: 0.5625rem;
  padding-bottom: 0.5rem;
  background-color: #83754d;
}

@media screen and (max-width: 735px) {
  #top .top__header {
    padding-top: 0.65625rem;
    padding-bottom: 0.53125rem;
  }
}

#top .top__brand {
  margin: 0;
  margin-bottom: 0.21875rem;
  font-size: 10px;
  font-size: 0.625rem;
  color: #cdb570;
  text-align: center;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .top__brand {
    margin-bottom: 0.28125rem;
  }
}

#top .top__producedby {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  font-style: italic;
  color: #cdb570;
  text-align: center;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .top__producedby {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

#top .top__logo {
  position: absolute;
  width: 6.7vw;
  top: 0.7vw;
  left: 5vw;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #top .top__logo {
    width: 15vw;
    top: 5vw;
    left: 5vw;
  }
}

#top .top__main {
  position: relative;
  background-color: #cdb570;
}

#top .top__main-bg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 735px) {
  #top .top__main-bg {
    padding-top: 4vw;
    padding-bottom: 10vw;
  }
}

#top .top__main-bg--left {
  width: 40vw;
  display: flex;
  position: relative;
}

#top .top__main-bg--left img {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 735px) {
  #top .top__main-bg--left img {
    position: relative;
  }
}

@media screen and (max-width: 735px) {
  #top .top__main-bg--left {
    width: 100vw;
  }
}

#top .top__main-bg--right {
  width: 40vw;
}

@media screen and (max-width: 735px) {
  #top .top__main-bg--right {
    width: 0vw;
  }
}

#top .top__main-title {
  width: 30vw;
  position: absolute;
  top: 4vw;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 11;
}

@media screen and (max-width: 735px) {
  #top .top__main-title {
    width: 60vw;
    top: 60vw;
  }
}

#top .top__main-gradient {
  width: 100vw;
  height: 13vw;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(81, 67, 98, 0), #514362);
}

@media screen and (max-width: 735px) {
  #top .top__main-gradient {
    height: 50vw;
  }
}

#top .sqs-row:nth-child(2) {
  max-width: 75rem;
  margin: 0 auto !important;
  position: relative;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #top .sqs-row:nth-child(2) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

#top .sqs-row:nth-child(2) .sqs-col-8 {
  margin-top: -8vw;
}

@media screen and (max-width: 735px) {
  #top .sqs-row:nth-child(2) .sqs-col-8 {
    margin-top: 39vw;
  }
}

#top .sqs-row:nth-child(2) p {
  margin-top: 2vw;
  margin-bottom: 2vw;
}

@media screen and (max-width: 735px) {
  #top .sqs-row:nth-child(2) p {
    margin-top: 2.125rem;
    margin-bottom: 2.125rem;
  }
}

@media screen and (max-width: 735px) {
  #point .index-section-wrapper {
    padding: 0;
  }
  #point .sqs-layout > .sqs-row {
    margin: 0;
  }
  #point .code-block {
    padding: 0;
  }
}

#set .purchase__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 735px) {
  #set .purchase__container {
    flex-direction: column;
  }
}

#set .purchase__pierce,
#set .purchase__earrings {
  width: 50%;
}

@media screen and (max-width: 735px) {
  #set .purchase__pierce,
  #set .purchase__earrings {
    width: 95%;
  }
}

@media screen and (max-width: 735px) {
  #set .purchase__pierce {
    margin-bottom: 1.25rem;
  }
}

#set .item__button {
  padding: 0;
  background: transparent;
  border: none;
}

#chocolat {
  padding-bottom: 2rem;
  background-color: #e6e2d4;
}

#chocolat .sqs-layout > .sqs-row {
  margin: 0;
}

#chocolat .index-section-wrapper {
  background-color: #e1b0be;
}

#chocolat .page-content {
  max-width: 100%;
  margin-right: 1.3125rem;
  margin-left: 1.3125rem;
  padding: 0;
  padding-top: 4.0625rem;
  padding-bottom: 5.3125rem;
  border: 0.125rem solid #d3c17a;
  box-shadow: 0.0625rem 0.0625rem 0.375rem 0.0625rem rgba(0, 0, 0, 0.46);
}

@media screen and (max-width: 735px) {
  #chocolat .page-content {
    margin-right: 0.6875rem;
    margin-left: 0.6875rem;
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
  }
}

#chocolat .page-content .content-inner {
  max-width: 63.75rem;
  margin: 0 auto;
}

#chocolat .purchase__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 735px) {
  #chocolat .purchase__container {
    flex-direction: column;
  }
}

#chocolat .purchase__pierce,
#chocolat .purchase__earrings {
  width: 50%;
}

@media screen and (max-width: 735px) {
  #chocolat .purchase__pierce,
  #chocolat .purchase__earrings {
    width: 100%;
  }
}

@media screen and (max-width: 735px) {
  #chocolat .purchase__pierce {
    margin-bottom: 1.25rem;
  }
}

#chocolat .item__button {
  padding: 0;
  background: transparent;
  border: none;
}

#vanilla {
  padding-bottom: 2rem;
  background-color: #e6e2d4;
}

#vanilla .sqs-layout > .sqs-row {
  margin: 0;
}

#vanilla .index-section-wrapper {
  background-color: #c7adcd;
}

#vanilla .page-content {
  max-width: 100%;
  margin-right: 1.3125rem;
  margin-left: 1.3125rem;
  padding: 0;
  padding-top: 4.0625rem;
  padding-bottom: 5.3125rem;
  border: 0.125rem solid #d3c17a;
  box-shadow: 0.0625rem 0.0625rem 0.375rem 0.0625rem rgba(0, 0, 0, 0.46);
}

@media screen and (max-width: 735px) {
  #vanilla .page-content {
    margin-right: 0.6875rem;
    margin-left: 0.6875rem;
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
  }
}

#vanilla .page-content .content-inner {
  max-width: 63.75rem;
  margin: 0 auto;
}

#vanilla .purchase__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 735px) {
  #vanilla .purchase__container {
    flex-direction: column;
  }
}

#vanilla .purchase__pierce,
#vanilla .purchase__earrings {
  width: 50%;
}

@media screen and (max-width: 735px) {
  #vanilla .purchase__pierce,
  #vanilla .purchase__earrings {
    width: 100%;
  }
}

@media screen and (max-width: 735px) {
  #vanilla .purchase__pierce {
    margin-bottom: 1.25rem;
  }
}

#vanilla .item__button {
  padding: 0;
  background: transparent;
  border: none;
}

#pierre {
  padding-bottom: 6rem;
  background-color: #e6e2d4;
}

#pierre .sqs-layout > .sqs-row {
  margin: 0;
}

#pierre .index-section-wrapper {
  background-color: #102e4d;
}

#pierre .page-content {
  max-width: 100%;
  margin-right: 1.3125rem;
  margin-left: 1.3125rem;
  padding: 0;
  padding-top: 4.0625rem;
  padding-bottom: 5.3125rem;
  border: 0.125rem solid #d3c17a;
  box-shadow: 0.0625rem 0.0625rem 0.375rem 0.0625rem rgba(0, 0, 0, 0.46);
}

@media screen and (max-width: 735px) {
  #pierre .page-content {
    margin-right: 0.6875rem;
    margin-left: 0.6875rem;
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
  }
}

#pierre .page-content .content-inner {
  max-width: 63.75rem;
  margin: 0 auto;
}

#pierre .purchase__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 735px) {
  #pierre .purchase__container {
    flex-direction: column;
  }
}

#pierre .purchase__pierce,
#pierre .purchase__earrings {
  width: 50%;
}

@media screen and (max-width: 735px) {
  #pierre .purchase__pierce,
  #pierre .purchase__earrings {
    width: 100%;
  }
}

@media screen and (max-width: 735px) {
  #pierre .purchase__pierce {
    margin-bottom: 1.25rem;
  }
}

#pierre .item__button {
  padding: 0;
  background: transparent;
  border: none;
}

body {
  padding-bottom: 3.4375rem;
}

@media screen and (max-width: 735px) {
  body {
    padding-bottom: 4.3125rem;
  }
}

#floatingfooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

#floatingfooter .Index-page-content {
  padding: 0;
}

#floatingfooter .sqs-layout > .sqs-row {
  margin: 0;
}

#floatingfooter .sqs-block {
  padding: 0 !important;
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  width: 100vw;
  height: 3.4375rem;
  height: constant(55pxenv(safe-area-inset-bottom));
  height: calc(55px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #514362;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#floatingfooter * {
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #floatingfooter {
    height: 4.3125rem;
    height: constant(69pxenv(safe-area-inset-bottom));
    height: calc(69px + env(safe-area-inset-bottom));
  }
}

#floatingfooter .page-content {
  padding: 0;
}

#floatingfooter .container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container {
    max-width: 20rem;
  }
}

#floatingfooter .container .item {
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
}

#floatingfooter .container .item__amount {
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
}

#floatingfooter .container .item__amount--large {
  font-size: 16px;
  font-size: 1rem;
}

#floatingfooter .container .item__text {
  margin: 0;
  margin-top: 0.375rem;
  font-size: 10px;
  font-size: 0.625rem;
}

#floatingfooter .container .item__button {
  width: 34%;
  margin-left: 0.75rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#floatingfooter .container .item__button img {
  transform: translateY(0.0625rem);
}

#modal .Index-page-content {
  padding: 0;
}

#modal .sqs-layout > .sqs-row {
  margin: 0;
}

#modal .sqs-block {
  padding: 0 !important;
}

#modal {
  line-height: 1;
}

#modal .page-content {
  padding: 0;
}

#modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateY(100vh);
  transition: transform 10ms 300ms;
  z-index: 1000;
}

#modal .modal.open {
  transform: translateY(0);
  transition: transform 10ms;
}

#modal .modal.open .modal__backdrop {
  background-color: rgba(0, 0, 0, 0.53);
  transition: background-color 300ms 10ms;
}

#modal .modal.open .modal__content {
  transform: translateY(0);
  transition: transform 300ms;
}

#modal .modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}

#modal .modal__content {
  position: relative;
  transform: translateY(100vh);
  transition: transform 300ms;
  overflow: visible;
}

#modal .modal__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.3125rem 0.4375rem;
  font-size: 19px;
  font-size: 1.1875rem;
  background-color: #e8e5e5;
  border-radius: 50%;
  transform: translate(0.5rem, -0.5625rem);
  cursor: pointer;
}

#modal .content {
  background-color: #e6e2d4;
  border-radius: 0.375rem;
}

#modal .content__inner {
  padding: 2.25rem 2.1875rem 2.25rem 2.1875rem;
}

@media screen and (max-width: 374px) {
  #modal .content__inner {
    padding: 1rem 0.9375rem 1rem 0.9375rem;
  }
}

@media screen and (max-width: 735px) {
  #modal .content__items {
    max-height: 55vh;
    overflow-y: scroll;
  }
}

#modal .item {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.0625rem solid #999375;
}

#modal .item__set {
  padding-top: 0;
}

#modal .item__info {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

#modal .item__text {
  margin-right: 0.625rem;
}

#modal .item__title {
  margin: 0;
  margin-bottom: 0.4375rem;
  font-size: 0.875rem;
}

#modal .item__price {
  margin: 0;
  font-size: 0.875rem;
}

#modal .item__tax {
  margin-left: 0.3125rem;
  font-size: 0.625rem;
}

#modal .item__image {
  width: 2.3125rem;
  height: 2.3125rem;
}

#modal .item__purchase {
  display: flex;
  justify-content: center;
}

#modal .item__date {
  margin: 0;
  margin-top: 0.625rem;
  font-size: 12px;
  font-size: 0.75rem;
}

#modal .purchase__button {
  padding: 0;
  background: transparent;
  border: none;
}

#modal .purchase__image {
  width: 7.0625rem;
}

#footer .back-to-top-nav {
  display: none;
}
