#top {
  .sqs-layout {
    .sqs-row {
      margin: 0 !important;
    }
  }

  .sqs-block {
    padding: 0;

    @include mq() {
      padding: 0 !important;
    }
  }

  .index-section-wrapper {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0;
    padding: 0;
    padding-bottom: pxToRem(64);

    @include mq() {
      padding-bottom: pxToRem(32);
    }
  }

  .top {
    &__header {
      position: relative;
      width: 100vw;
      padding-top: pxToRem(9);
      padding-bottom: pxToRem(8);
      background-color: #83754d;

      @include mq() {
        padding-top: pxToRem(10.5);
        padding-bottom: pxToRem(8.5);
      }
    }

    &__brand {
      margin: 0;
      margin-bottom: pxToRem(3.5);
      @extend .font--ten-mincho;
      @include fontSizePxToRem(10);
      color: #cdb570;
      text-align: center;
      line-height: 1;

      @include mq() {
        margin-bottom: pxToRem(4.5);
      }
    }

    &__producedby {
      margin: 0;
      @extend .font--eb-garamond;
      @include fontSizePxToRem(14);
      font-style: italic;
      color: #cdb570;
      text-align: center;
      line-height: 1;

      @include mq() {
        @include fontSizePxToRem(12);
      }
    }

    &__logo {
      position: absolute;
      width: 6.7vw;
      top: 0.7vw;
      left: 5vw;
      z-index: 5;

      @include mq() {
        width: 15vw;
        top: 5vw;
        left: 5vw;
      }
    }

    &__main {
      position: relative;
      background-color: #cdb570;
    }

    &__main-bg {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include mq() {
        padding-top: 4vw;
        padding-bottom: 10vw;
      }

      &--left {
        width: 40vw;
        display: flex;
        position: relative;

        img {
          position: absolute;
          left: 0;
          bottom: 0;

          @include mq() {
            position: relative;
          }
        }

        @include mq() {
          width: 100vw;
        }
      }

      &--right {
        width: 40vw;

        @include mq() {
          width: 0vw;
        }
      }
    }

    &__main-title {
      width: 30vw;
      position: absolute;
      top: 4vw;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 11;

      @include mq() {
        width: 60vw;
        top: 60vw;
      }
    }

    &__main-gradient {
      width: 100vw;
      height: 13vw;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(81, 67, 98, 0), rgb(81, 67, 98));

      @include mq() {
        height: 50vw;
      }
    }
  }

  .sqs-row {
    &:nth-child(2) {
      max-width: pxToRem(1200);
      margin: 0 auto !important;
      position: relative;
      z-index: 5;

      @include mq() {
        padding-left: pxToRem(20);
        padding-right: pxToRem(20);
      }

      .sqs-col-8 {
        margin-top: -8vw;

        @include mq() {
          margin-top: 39vw;
        }
      }

      p {
        margin-top: 2vw;
        margin-bottom: 2vw;

        @include mq() {
          margin-top: pxToRem(34);
          margin-bottom: pxToRem(34);
        }
      }
    }
  }
}