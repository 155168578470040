#set {
  .purchase__container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq() {
      flex-direction: column;
    }
  }

  .purchase__pierce,
  .purchase__earrings {
    width: 50%;

    @include mq() {
      width: 95%;
    }
  }

  .purchase__pierce {
    @include mq() {
      margin-bottom: pxToRem(20);
    }
  }

  .item__button {
    padding: 0;
    background: transparent;
    border: none;
  }
}