.font {
  &--ten-mincho {
    font-family: ten-mincho, serif;
    font-style: normal;
    font-weight: 400;
  }

  &--eb-garamond {
    font-family: eb-garamond, serif;
    font-style: normal;
    font-weight: 400;
  }
}